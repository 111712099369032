import React, { Component } from "react"
import "./Skills.scss"

export default class Skills extends Component<any, any> {
    render() {
        return <div className={ "skills" }>
            <section className={ "skills-container" }>
                <h1 className={ "heading title fade-in" } id={ "skills" }>
                    <i className={ "fas fa-code" }/>
                    My Skills
                </h1>

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <i className="fa-brands fa-java fa-2xl blur-in"></i>
                        </td>
                        <td>
                            <span className="blur-in">Java</span>
                            <span className="blur-in">2013 { '\u00B7' } Expert</span>
                        </td>
                        <td>
                            <div className="skill-progress" style={ { width: "100%" } }></div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <i className="fa-brands fa-html5 fa-2xl blur-in"></i>
                        </td>
                        <td>
                            <span className="blur-in">HTML</span>
                            <span className="blur-in">2010 { '\u00B7' } Expert</span>
                        </td>
                        <td>
                            <div className="skill-progress" style={ { width: "100%" } }></div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <i className="fa-brands fa-css3 fa-2xl blur-in"></i>
                        </td>
                        <td>
                            <span className="blur-in">CSS</span>
                            <span className="blur-in">2012 { '\u00B7' } Expert</span>
                        </td>
                        <td>
                            <div className="skill-progress" style={ { width: "100%" } }></div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <i className="fa-brands fa-js fa-2xl blur-in"></i>
                        </td>
                        <td>
                            <span className="blur-in">JavaScript</span>
                            <span className="blur-in">2014 { '\u00B7' } Advanced</span>
                        </td>
                        <td>
                            <div className="skill-progress" style={ { width: "95%" } }></div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <i className="fa-brands fa-php fa-2xl blur-in"></i>
                        </td>
                        <td>
                            <span className="blur-in">PHP</span>
                            <span className="blur-in">2017 { '\u00B7' } Intermediate</span>
                        </td>
                        <td>
                            <div className="skill-progress" style={ { width: "70%" } }></div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <i className="fa-brands fa-swift fa-2xl blur-in"></i>
                        </td>
                        <td>
                            <span className="blur-in">Swift</span>
                            <span className="blur-in">2022 { '\u00B7' } Beginner</span>
                        </td>
                        <td>
                            <div className="skill-progress" style={ { width: "40%" } }></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>

            <section className="skills-container-lower fade-in">
                <i className="fa-brands fa-git fa-2x" title="Git"></i>
                <i className="fa-brands fa-node fa-2x" title="NodeJS"></i>
                <i className="fa-brands fa-npm fa-2x" title="NPM"></i>
                <i className="fa-brands fa-react fa-2x" title="React"></i>
                <i className="fa-brands fa-linux fa-2x" title="Linux"></i>
                <i className="fas fa-server fa-2x" title="Server"></i>
                <i className="fas fa-database fa-2x" title="SQL"></i>
                <i className="fa-brands fa-stack-overflow fa-2x" title="Stack Overflow"></i>
                <i className="fa-brands fa-sass fa-2x" title="Sass"></i>
            </section>
        </div>
    }
}
