import React, { Component } from "react"
import "./Timeline.scss"
import bmc1 from "../images/bmc1.png"
import bmc2 from "../images/bmc2.png"
import simplechat from "../images/simplechat.png"

export default class Timeline extends Component<any, any> {
    static updateTimeline = () => {
        // @ts-ignore
        let height = window.scrollY + window.innerHeight * 0.8 - document.querySelector(".timeline")?.offsetTop

        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
            height = document.body.offsetHeight
        } else if (window.scrollY === 0) {
            height = 0
        }

        // @ts-ignore
        document.querySelector(".timeline")?.style.setProperty("--timeline-height", `${ height }px`)

        document.querySelectorAll(".timeline-item").forEach((item: any) => {
            if (item.offsetTop < height) {
                item.classList.add("timeline-item-active")
            } else {
                item.classList.remove("timeline-item-active")
            }
        })
    }

    componentDidMount() {
        Timeline.updateTimeline()
    }

    render() {
        return <section>
            <h1 className={ "heading title fade-in" } id={ "timeline" }>
                <i className={ "fas fa-timeline" } style={ { transform: "rotateZ(90deg)" } }/>
                My Timeline
            </h1>

            <div className={ "timeline" }>
                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2013</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fas fa-server" }/>
                            <span className={ "animated-gradient-background" }>Minecraft Server</span>
                            Your-Game
                        </h1>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-code animated-gradient-background" }></i>
                                First experience with coding
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fas fa-bullhorn animated-gradient-background" }></i>
                                First experience with building a team
                            </p>
                        </div>
                    </div>
                </div>

                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2018</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fa-brands fa-youtube" }/>
                            <span className={ "animated-gradient-background" }>Achievement</span>
                            YouTube Presence
                        </h1>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-tower-broadcast animated-gradient-background" }></i>
                                Meeting new people live streaming their experience on my server
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fas fa-church animated-gradient-background" }></i>
                                Engaging with the community
                            </p>
                        </div>
                    </div>
                </div>

                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2017</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fas fa-network-wired" }/>
                            <span className={ "animated-gradient-background" }>Minecraft Network</span>
                            Your-Game Group
                        </h1>

                        <img src={ bmc1 }
                             alt={ "Minecraft" }
                             style={ {
                                 width: "100%",
                                 borderRadius: "var(--border-radius)",
                                 filter: "contrast(1.2)"
                             } }/>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-chalkboard-user animated-gradient-background" }></i>
                                Lead of the entire network
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fas fa-people-group animated-gradient-background" }></i>
                                Team of 15 people
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fas fa-code animated-gradient-background" }></i>
                                100% custom coded, 30k+ lines of code
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fa-brands fa-teamspeak animated-gradient-background" }></i>
                                TeamSpeak server, bots & integrations
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fas fa-chart-simple animated-gradient-background" }></i>
                                Frontend for visualizing player stats
                            </p>
                        </div>
                    </div>
                </div>

                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2018</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fas fa-hand-holding-dollar" }/>
                            <span className={ "animated-gradient-background" }>Achievement</span>
                            First Income
                        </h1>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-calendar-plus animated-gradient-background" }></i>
                                December 2017
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fas fa-turn-up animated-gradient-background" }></i>
                                Increased server budget & performance
                            </p>
                        </div>
                    </div>
                </div>

                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2018</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fas fa-trophy" }/>
                            <span className={ "animated-gradient-background" }>Achievement</span>
                            80+ Players
                        </h1>

                        <iframe src="https://www.youtube-nocookie.com/embed/MC6BZG7PwCQ"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                style={ {
                                    width: "100%",
                                    aspectRatio: "16 / 9",
                                    borderRadius: "var(--border-radius)"
                                } }></iframe>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-rocket animated-gradient-background" }></i>
                                Increased player count by 800%, hitting 80+ players, limited by server hardware
                            </p>

                            <hr/>

                            <p className={ "paragraph" }>
                                <i className={ "fas fa-plug-circle-xmark animated-gradient-background" }></i>
                                Performance issues due to bad server hardware & DDoS attacks
                            </p>
                        </div>
                    </div>
                </div>

                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2018- 2020</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fas fa-cube" }/>
                            <span className={ "animated-gradient-background" }>Minecraft Network</span>
                            BenjoMC
                        </h1>

                        <img src={ bmc2 }
                             alt={ "Minecraft" }
                             style={ { width: "100%", borderRadius: "var(--border-radius)" } }/>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-chalkboard-user animated-gradient-background" }></i>
                                Lead of the entire network
                            </p>
                        </div>
                    </div>
                </div>

                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2023</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fas fa-comment-dots" }/>
                            <span className={ "animated-gradient-background" }>Project</span>
                            <a href={ "https://github.com/benniloidl/simplechat" } target={ "_blank" }>
                                SimpleChat
                                <i className={ "fas fa-arrow-up-right-from-square" }/>
                            </a>
                        </h1>

                        <img src={ simplechat }
                             alt={ "SimpleChat" }
                             style={ {
                                 width: "100%",
                                 borderRadius: "var(--border-radius)",
                                 filter: "contrast(1)"
                             } }/>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-people-group animated-gradient-background" }></i>
                                Team of 4 people
                            </p>
                        </div>
                    </div>
                </div>

                <div className={ "timeline-item" }>
                    <div className={ "timeline-point" }>2023</div>
                    <div className={ "timeline-content" }>
                        <h1 className={ "heading" }>
                            <i className={ "fas fa-comments" }/>
                            <span className={ "animated-gradient-background" }>Project</span>
                            <a href={ "https://github.com/benniloidl/simpleq" } target={ "_blank" }>
                                simpleQ
                                <i className={ "fas fa-arrow-up-right-from-square" }/>
                            </a>
                        </h1>

                        <div className={ "card" }>
                            <p className={ "paragraph" }>
                                <i className={ "fas fa-hourglass-start animated-gradient-background" }></i>
                                Coming soon..
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}
