import { Component } from "react"
import "./Navigation.scss"

interface NavigationItem {
    name: string,
    id: string
}

interface State {
    navigationItems: NavigationItem[]
}

export default class Navigation extends Component<any, State> {
    constructor(props: any) {
        super(props)
        this.state = {
            navigationItems: []
        }
    }

    componentDidMount() {
        document.querySelectorAll(".title").forEach((element: any) => {
            if (this.state.navigationItems.find((item) => item.id === element.id) === undefined) {
                let newNavigationItems = this.state.navigationItems
                newNavigationItems.push({
                    name: element.textContent,
                    id: element.id
                })
                this.setState({
                    navigationItems: newNavigationItems
                })
            }
        })
    }

    render() {
        return <nav className={ "navigation" }>
            <div>
                <p>Benni Loidl</p>

                <div className={ "navigation-items" }>
                    { this.state.navigationItems.map((item, index) => {
                        return <a href={ "#" + item.id }
                                  id={ item.id + "-link" }
                                  className={ "navigation-item" }
                                  key={ index }>{ item.name }</a>
                    }) }
                </div>

                <div className={ "underline" }/>
            </div>
        </nav>
    }
}
