import React, { Component } from "react"
import "./Loader.scss"

export default class Loader extends Component<any, any> {
    render() {
        return <div className={ "loader" }>
            <div className={ "lds-ellipsis" }>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    }
}